<template>
    <div class="dots d-flex flex-column py-5" style="height: 300px">
        <h1 class="mx-auto mb-3">Co děláme?</h1>
        <a href="#sluzby" class="mt-auto" id="sluzby">
        <div class="inner-circle mx-auto mt-auto">
            <div class="play"></div>
        </div>
        </a>
    </div>
</template>

<script>
export default {
    name: "DotsComponent2"
}
</script>

<style scoped>

</style>
