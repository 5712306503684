<template>
    <footer class="py-1">
        <div class="container row flex-row mx-auto py-3">
            <div class="col-7 align-items-center d-flex" style="font-size: 15px; font-weight: 550">
                COPYRIGHT © 2021 NB PROJEKCE. S.R.O.
            </div>

            <div class="col-5">
                <div class="credit">
                <div>Ikony od <a href="https://www.freepik.com" title="Freepik">Freepik</a></div>
                <div class="softici">
                    Stránky vytvořili
                    <a href="//softici.cz">Softíci.cz</a>
                    <img src="/images/softici_badge_lg.png" class="softiciimg">
                </div>
                </div>
            </div>
        </div>

    </footer>
</template>

<script>
export default {
    name: "footer"
}
</script>

<style scoped>

.credit{
    margin-left: auto;
    max-width: 200px;
}

footer {
    margin-top: auto;
}


/*.col-9, .col-3 {*/
/*    padding: 0 !important;*/
/*    font-size: 10px;*/
/*}*/

@media (max-width: 720px){
    .col-7, .col-5 {
        padding: 0 !important;
        font-size: 10px;
    }
}

</style>
