<template>
    <div>
    <div class="my-5">
        <div class="containerFlex" style="max-width: 1450px;">


        <div class="col-md-5 d-flex" style="max-height: 650px">
            <div class="photoContainer flex-column">
                <img :src="project.main_photo" class="mainPhoto" data-toggle="modal" data-target="#galleryModal" @click="clickedPhoto = 0">
                <div class="row align-content-between m-0 flex-nowrap photoRow">
                    <div v-for="(photo, index) in project.photos" class="w-49 mt-2 photoRowContainer">
                        <img :src="photo.path" class="secondaryPhoto" :class="'photo' + index" data-toggle="modal" data-target="#galleryModal" @click="clickedPhoto = index + 1">
                    </div>
                </div>
                <img :src="project.house_plan" class="mainPhoto mt-2" v-if="project.house_plan" data-toggle="modal" data-target="#galleryModal" @click="clickedPhoto = photoNum">
            </div>
        </div>
        <div class="col-md-7 d-flex flex-column" style="max-height: 650px">
            <div class="introtext projectNameB mb-2">
                <h5 class="introtext projectName">{{project.name}}</h5>
            </div>
            <div class="projectDescription">
                <div class="introtext mb-2" style="text-align: left; color: rgba(139, 0, 0, 0.8)">Popis stavby:</div><p style="white-space:pre-wrap;" v-html="project.info"></p>
            </div>
        </div>
    </div>
    </div>
<!--        <a href="/#projekty">-->
<!--            <i class="fas fa-arrow-left"></i>-->
<!--        </a>-->
        <div class="modal" tabindex="-1" role="dialog" id="galleryModal">
            <div class=" modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content border-0">
                    <div class="galleryContainer">
                        <div id="carouselExampleIndicators" class="carousel slide " data-ride="carousel">

                            <ol class="carousel-indicators">
                                <li data-target="#carouselExampleIndicators" data-slide-to="0"
                                    :class="[clickedPhoto === 0 ? 'active' : '']"></li>
                                <li data-target="#carouselExampleIndicators" v-for="(photo, index) in project.photos"
                                    :data-slide-to="index + 1"
                                    :class="[clickedPhoto === index + 1  ? 'active' : '']"></li>
                                <li data-target="#carouselExampleIndicators" :data-slide-to="photoNum"
                                    :class="[clickedPhoto === photoNum ? 'active' : '']"></li>

                            </ol>
                            <div class="carousel-inner">
                                <div class="carousel-item" :class="[clickedPhoto === 0 ? 'active' : '']">
                                    <img class="d-block w-100 galleryPhoto" :src="project.main_photo"
                                         alt="Hlavní fotka">
                                </div>
                                <div class="carousel-item" v-for="(photo, index) in project.photos"
                                     :class="[clickedPhoto === index + 1  ? 'active' : '']">
                                    <img class="d-block w-100 galleryPhoto" :src="photo.path" alt="Další fotka">
                                </div>
                                <div class="carousel-item" :class="[clickedPhoto === photoNum ? 'active' : '']">
                                    <img class="d-block w-100 galleryPhoto" :src="project.house_plan" alt="Další fotka">
                                </div>
                            </div>
                            <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button"
                               @click="prevPhoto()">
                                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                <span class="sr-only">Previous</span>
                            </a>
                            <a class="carousel-control-next" href="#carouselExampleIndicators" role="button"
                               @click="nextPhoto()">
                                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                <span class="sr-only">Next</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
<!--        <div class="jumbotron">-->
<!--            <h1>{{project.name}}-->
<!--            </h1>-->
<!--            <p>{{project.info}}</p>-->
<!--        </div>-->


</template>

<script>

export default {
    name: "SingleProjectComponent",
    props: ["project"],
    data: () => {
        return {
            gallery: false,
            photoNum: null,
            clickedPhoto: null
        }
    },
    mounted() {
        this.photoNum = this.project.photos.length + 1
    },
    methods: {
        nextPhoto: function () {
            if (this.clickedPhoto === this.photoNum)
            {
                this.clickedPhoto = 0;
            } else {
                this.clickedPhoto += 1
            }
        },

        prevPhoto: function () {
            if (this.clickedPhoto === 0)
            {
                this.clickedPhoto = this.photoNum;
            } else {
                this.clickedPhoto -= 1
            }
        }
    }
}
</script>

<style scoped>

/*.carousel-inner img {*/
/*    width: 100%;*/
/*    height: 100vh;*/
/*    object-fit: cover;*/
/*}*/

.modal-dialog {
    max-width: 1200px;
    height: 100%;
}

.modal-backdrop {
    height: 100% !important;
}

.galleryContainer {
}

.active {
    transition: transform 0.6s ease-in-out;
}

.galleryPhoto {
    height: 700px; width: 100%;
    object-fit: cover;

    transition: transform 0.6s ease-in-out;
}

.galleryFilter {
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.2);
}

.mainPhoto {
    width: 100%;
    height: auto;
    max-height: 350px;
    min-height: 190px;
    object-fit: cover;
    cursor: pointer;
}

.secondaryPhoto {
    width: 100%;
    height: auto;
    max-width: 177px;
    object-fit: cover;
    cursor: pointer;
}

.photoContainer {
    display: flex;
    margin: 0 auto;
    max-width: 500px;
}

.photoRowContainer {
    display: flex;
    max-width: 161px;
    object-fit: cover;
}

.projectDescription {

    padding: 20px;
    width: 96%;
    font-size: 17px;

}

.projectName {
    text-align: left;
    color: rgba(139, 0, 0, 0.8);
    font-weight: 500;
    font-size: 40px;
    padding-bottom: 10px;
    position: relative;
}
.projectName:after {
    content: "";
    background: rgba(139, 0, 0, 0.8);
    position: absolute;
    bottom: 0;
    left: 0;
    height: 6px;
    width: 40%;
}

.projectNameB {
    background-color: #e8e6e7;
    padding: 20px;
    width: 100%;
    margin: 0;
}

.containerFlex {
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
    margin: auto;
}

.photoRow {
    justify-content: space-between;
}



@media (max-width: 768px) {
.col-md-5 {
    padding-bottom: 40px;
}
    .photoContainer {
        max-width: 100%;
    }

    .mainPhoto {
        max-height: 100%;
    }

    .secondaryPhoto {
        max-height: 100%;
        max-width: 100%;
    }

    .photoRowContainer {
        max-width: 100%;
    }

    .modal-dialog-centered {
        min-height: calc(50% - 1rem)
    }
}

/*@media (min-width: 1450px) {*/
/*    .secondaryPhoto {*/
/*        width: 177px;*/
/*    }*/

/*    .photoRow {*/
/*        justify-content: center;*/
/*    }*/
/*}*/

@media (max-width: 1370px) {
    .modal-dialog {
        max-width: 857px;
        max-height: 500px;
    }

    .galleryPhoto {
        height: 500px;
    }
}

@media (max-width: 1250px) {
    /*.photo0 {*/
    /*    margin-right: 0.5rem !important;*/
    /*}*/


    /*.photo2 {*/
    /*    margin-left: 0.5rem !important;*/
    /*}*/

    /*.photo3 {*/
    /*    margin-left: 0.5rem;*/
    /*}*/

    .photo0 {
        border-right: 0.5rem solid white !important;
    }


    .photo2 {
        border-left: 0.5rem solid white !important;
    }

    .photo3 {
        border-left: 0.5rem solid white !important;
    }
}

@media (min-width: 768px) {
    .photoRow {
        min-height: 80px;
        overflow: hidden;
    }
}

@media (max-width: 500px) {
    .col-md-7 {
        padding-right: 0;
        padding-left: 0;
    }
}
</style>
