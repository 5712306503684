<template>
<div id="projects" class="container-fluid" v-if="!loading">
    <div class="mask-projects">
    <div class="row p-row">
        <div class="col-md p-col" v-for="(project, i) in projects.list" v-if="i < 3">
            <img :src="project.main_photo_small" class="p-image">
            <h3 class="p-name">{{ project.name }}</h3>
            <div class="p-date">Realizace {{ new Date(project.realization).getFullYear() }}</div>
            <a class="btn btn-success p-button" :href="'/projekt/'+project.url">Více</a>
        </div>
    </div>
    <div class="row p-row">
        <div class="col-md" v-for="(project, i) in projects.list" v-if="i >= 3">
            <img :src="project.main_photo_small" class="p-image">
            <h3 class="p-name">{{ project.name }}</h3>
            <div class="p-date">Realizace {{ new Date(project.realization).getFullYear() }}</div>
            <a class="btn btn-success p-button" :href="'/projekt/'+project.url">Více</a>
        </div>
    </div>
    </div>

    <div class="w-100 mt-4 text-center pb-3"><h3><a class="red" href="/dalsi-projekty">Další projekty</a></h3></div>
</div>
</template>

<script>
import DotLoader from "vue-spinner/src/DotLoader";
import moment from "moment";

export default {
    name: "ProjectsDisplayComponent",
    components: {
        DotLoader
    },
    data: () => {
        return {

            projects: {
                data: {},
                list: []
            },
            loading: true
        }
    },
    mounted() {
        this.getProjects();
        window.addEventListener('DOMContentLoaded', () => {
            this.loading = false;
        })

    },
    methods: {

        getProjects() {
            axios.get("/api/projekty/list?timestamp="+moment().unix())
                .then(res => {
                    this.projects.data = res.data
                    this.projects.list = res.data.data
                }).finally(() => {
            })
        }
    }
}
</script>

<style scoped>
    .col-md {
        padding: 0;
        flex-direction: column;
    }

    .container-fluid {
        padding: 0;
    }

    @media screen and (max-width: 1000px){
        .p-row {
            flex-direction: column !important;
            width: 50%;
            margin: 0;
        }

        .mask-projects {
            flex-direction: row;
            display: flex;
        }

    }

    @media screen and (max-width: 768px){
        .p-row {
            flex-direction: row !important;
            width: 100%;
        }

        .mask-projects {
            flex-direction: column;
            display: flex;
        }

    }
    .red {
        color: #8B0000;
    }
</style>
