<template>
<div id="projects" class="container-fluid py-3" v-if="!loading">
    <div class="mask-projects">
    <div class="d-flex flex-wrap flex-row">
        <div class="col-md min-vw" style="flex-basis: 0; flex-grow: 1" v-for="(project, i) in projects.data">
            <img :src="project.main_photo_small" class="p-image">
            <h3 class="p-name">{{ project.name }}</h3>
            <div class="p-date">Realizace {{ new Date(project.realization).getFullYear() }}</div>
            <a class="btn btn-success p-button" :href="'/projekt/'+project.url">Více</a>
        </div>
    </div>
    </div>
</div>
</template>

<script>
import DotLoader from "vue-spinner/src/DotLoader";
import moment from "moment";

export default {
    name: "MoreProjectsComponent",
    components: {
        DotLoader
    },
    data: () => {
        return {

            projects: {
                data: {},
            },
            loading: true
        }
    },
    mounted() {
        this.getProjects();
        window.addEventListener('DOMContentLoaded', () => {
            this.loading = false;
        })

    },
    methods: {

        getProjects() {
            axios.get("/api/more-projects?timestamp="+moment().unix())
                .then(res => {
                    this.projects.data = res.data
                }).finally(() => {
            })
        }
    }
}
</script>

<style scoped>
    .col-md {
        padding: 0;
        flex-direction: column;
    }

    .container-fluid {
        padding: 0;
    }

    @media screen and (max-width: 1000px){
        .p-row {
            flex-direction: column !important;
            width: 50%;
            margin: 0;
        }

        .mask-projects {
            flex-direction: row;
            display: flex;
        }

    }

    @media screen and (max-width: 768px){
        .p-row {
            flex-direction: row !important;
            width: 100%;
        }

        .mask-projects {
            flex-direction: column;
            display: flex;
        }

    }
    .min-vw {
        min-width: 450px;
    }
    @media screen and (max-width: 1300px) {
        .min-vw {
            min-width: 400px;
        }
    }
    @media screen and (max-width: 800px) {
        .min-vw {
            min-width: 350px;
        }
    }
    @media screen and (max-width: 500px) {
        .min-vw {
            min-width: 320px;
        }
    }
</style>
