<template>
    <div class="dots d-flex flex-column py-5">
        <div class="my-auto">
        <h1 class="mx-auto mb-5">Spolupracujeme</h1>
        <div class="container py-4" style="max-width: 1500px; background-color: #e8e6e7;">
                <div class="introcontainer d-flex flex-wrap flex-row">

                <div class="col-md-5">
                    <a href="https://www.nndum.cz" class="link mt-auto mx-auto" target="_blank">
                        <img src="/images/NND.svg" class="partner">
                    </a>
                </div>

                <div class="col-md-7">
                    <p class="partner-text my-auto">
                        S cílem dopřát Vám ty nejlepší možné služby jsme propojili síly stavební firmy SMI-ČR
                        a projekční kanceláře NB projekce a dali tak vzniknout našemu společnému projektu
                        Nízkonákladový dům. Zaměřujeme se na stavbu rodinných nízkonákladových domů na klíč.
                        Domy řešíme včetně dotací z programu Nová zelená úsporám. Více Informací naleznete
                        na samostatných stránkách projektu.
                    </p>
                </div>
            </div>

            <div class="container mt-5" style="max-width: 1400px">
            <div class="row toprow">

                <div class="col-sm">
                    <a href="https://www.smicrgroup.cz" class="link" target="_blank">
                        <img src="/images/smi-cr-stavebni_edited.webp" class="firmlogo">
                        <div class="firmname m-auto">SMI-ČR group s.r.o.</div>
                    </a>

                </div>

                <div class="col-sm">
                    <a href="https://www.ptacek.cz" class="link" target="_blank">
                        <img src="/images/ptacek.png" class="firmlogo">
                        <div class="firmname m-auto">Koupelny Ptáček</div>
                    </a>
                </div>

                <div class="col-sm">
                    <a href="https://www.oknacreative.cz" class="link" target="_blank">
                        <img src="/images/logocreative.png" class="firmlogo">
                        <div class="firmname m-auto">CREATIVE okna & dveře</div>
                    </a>
                </div>

                <div class="col-sm">
                    <a href="https://www.v-system.cz" class="link" target="_blank">
                        <img src="/images/logovsystem.png" class="firmlogo">
                        <div class="firmname m-auto">V-systém elektro, s.r.o.</div>
                    </a>
                </div>
            </div>

            <div class="row mx-auto bottomrow" style="max-width: 1050px">
                <div class="col-sm">
                    <a href="https://elektroinstall.cz/" class="link" target="_blank">
                        <img src="/images/logo_revize.png" class="firmlogo" style="padding: 15px 0">
                        <div class="firmname m-auto">Elektroinstall stream s.r.o.</div>
                    </a>
                </div>

                <div class="col-sm">
                    <a href="https://www.kominycz.cz/" class="link" target="_blank">
                        <img src="/images/logo_kominy.png" class="firmlogo">
                        <div class="firmname m-auto">Komíny CZ</div>
                    </a>
                </div>

                <div class="col-sm">
                    <a href="https://www.mvline.cz/" class="link" target="_blank">
                        <img src="/images/logo_mvline.png" class="firmlogo">
                        <div class="firmname m-auto">MV Line</div>
                    </a>
                </div>
            </div>
            </div>
            <div id="schuzka"></div>
        </div>
        </div>
    </div>

</template>

<script>
export default {
    name: "Collaboration"
}
</script>

<style scoped>
.col-md-7 {
    display: flex;
}

.col-md-5 {
    display: flex;
}

.col-sm {
    padding: 0;
    display: flex;
}

.link {
    display: flex;
    flex-direction: column;
    max-width: 350px;
    margin: 0 auto;
}

.link:hover {
    text-decoration: none;
}

.firmname {
color: black;
    text-decoration: none;
    font-size: 17px;
}

.firmlogo {
    max-height: 80px;
    max-width: 270px;
    margin: 20px auto;
}

.partner {
    height: 160px;
    width: auto;
    object-fit: contain;
    margin-bottom: 1rem;
}

.partner-text {
    font-size: 17px;
    line-height: 1.8;
}

/*.dots {*/
/*    height: 850px;*/
/*}*/

.toprow {
    margin-bottom: 1rem;
}

@media screen and (max-width: 992px) {
    .partner {
        height: 150px;
        margin: auto;
    }

    .toprow {
        margin-bottom: 0;
    }

    .link {
        margin-left: auto;
        margin-top: auto;
        margin-bottom: auto;
        width: 400px;
    }

    .partner-text {
        font-size: 15px;
        line-height: 1.7;
        margin: 0;
    }

    /*.dots {*/
    /*    height: 1020px;*/
    /*}*/
}

@media screen and (max-width: 768px) {
    .partner {
        width: 100%;
        height: auto;
    }

    .bottomrow {
        margin-left: -15px!important;
        margin-right: -15px!important;
    }

    .link {
        margin: 0 !important;
        width: 100%;
        height: auto;
        max-width: 100%;
        min-width: 270px;
    }

    .partner-text {
        font-size: 16px;
        line-height: 1.7;
        margin-bottom: 0;
        margin-top: 1rem;
    }

    /*.dots {*/
    /*    height: 1150px;*/
    /*}*/
}

@media screen and (max-width: 576px) {
    .link {
        margin: 10px auto!important;
    }

    /*.dots {*/
    /*    height: 1800px;*/
    /*}*/
}
</style>
