<template>
<!--    <div class="embedsocial-hashtag" data-ref="8193c14aeaaa9d66e7149167dff32687cfeba434" ></div>-->

    <!-- SnapWidget -->
    <iframe src="https://snapwidget.com/embed/959861" class="snapwidget-widget" allowtransparency="true" frameborder="0"
            scrolling="no" style="border:none; overflow:hidden;  width: 100%; height:450px"></iframe>
</template>

<script>
export default {
    name: "InstaFeedComponent",
    // mounted() {
    //     let feed = document.createElement('script');
    //     feed.text = '(function(d, s, id){var js; if (d.getElementById(id)) {return;} js = d.createElement(s); js.id = id; js.src = "https://embedsocial.com/cdn/ht.js"; d.getElementsByTagName("head")[0].appendChild(js);}(document, "script", "EmbedSocialHashtagScript"));'
    //     document.head.appendChild(feed)
    // }
}
</script>

<style scoped>
@media  (max-width: 1000px) {
    .snapwidget-widget {
        height: 200px !important;
    }
}

@media  (max-width: 600px) {
    .snapwidget-widget {
        height: 150px !important;
    }
}

@media  (max-width: 400px) {
    .snapwidget-widget {
        height: 90px !important;
    }
}
</style>
