<template>
<div id="boxes">
    <div class="container">
    <div class="box-container">
        <div class="box b1">
            <div class="b-top">
                <div class="b-head" style="padding-bottom: 0; max-width: 320px">ARCHITEKTONICKÉ STUDIE S VIZUALIZACÍ</div>
                <img src="/images/pillar.png" class="b-img">
            </div>

            <div class="b-text">
                <ul>
                <li>Osazení domu na pozemek</li>
                <li>Architektonický koncept</li>
                <li>Situace stavby</li>
                <li>Základní řešení interiéru</li>
                </ul>
            </div>
        </div>

        <div class="box b2">
            <div class="b-top">
                <div class="b-head">PROJEKTOVÁ DOKUMENTACE</div>
                <img src="/images/sketch.png" class="b-img">
            </div>

            <div class="b-text">
                <ul>
                <li>Projektová dokumentace všech stupňů</li>
                <li>Pasport stavby</li>
                <li>Dokumentace pro územní rozhodnutí</li>
                <li>Dokumentace pro stavební povolení</li>
                <li>Dokumentace pro provedení stavby</li>
                </ul>
            </div>
        </div>

        <div class="box b1 b3">
            <div class="b-top">
                <div class="b-head">ROZPOČET A ŘÍZENÍ STAVBY</div>
                <img src="/images/building.png" class="b-img">
            </div>

            <div class="b-text">
                <ul>
                <li>Kompletní rozpočet Vašeho záměru včetně výkazu výměr</li>
                <li>Uspořádáme výběrové řízení a výběr stavební firmy</li>
                <li>Harmonogram stavby</li>
                </ul>
            </div>
        </div>

        <div class="box b2">
            <div class="b-top">
                <div class="b-head" style="margin: 0 0 auto 25px">STAVEBNÍ DOZOR</div>
                <img src="/images/crane.png" class="b-img">
            </div>

            <div class="b-text">
                <ul>
                <li>Ověření kvality provedení prací na stavbě</li>
                <li>Nastavený rozpočet je pod kontrolou</li>
                </ul>
            </div>
        </div>

        <div class="box b1">
            <div class="b-top">
                <div class="b-head">DOTAČNÍ PROGRAMY</div>
                <img src="/images/hand.png" class="b-img">
            </div>

            <div class="b-text">
                <ul>
                <li>Získáme dotaci na Váš záměr</li>
                <li>Řešíme Novou zelenou úsporám a Dešťovku</li>
                <li>Pohybujeme se také v oblasti dotací pro firmy podnikatele</li>
                </ul>
            </div>
        </div>

        <div class="box b2 b3" id="spoluprace">
            <div class="b-top">
                <div class="b-head">INŽENÝRSKÁ ČINNOST</div>
                <img src="/images/contract.png" class="b-img">
            </div>

            <div class="b-text">
                <ul>
                <li>Vyřízení stavebního povolení</li>
                <li>Zajištění vyjádření DOSS</li>
                <li>Zastupování při stavebním řízení</li>
                </ul>
            </div>
        </div>
    </div>
    </div>
</div>
</template>

<script>
export default {
    name: "BoxesComponent"
}
</script>

<style scoped>
.container {
    max-width: 1500px;
}
</style>
