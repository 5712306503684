<template>
    <div class="d-flex">
        <template v-if="!loading">
            <div class="container row mt-5 mx-auto">
                <h1 class="w-100 mx-auto pb-5">Administrace projektů</h1>
                <div class="btn btn-primary ml-auto mr-3 mb-2" @click="toggle = !toggle" style="font-size: 18px">
                    <i class="far fa-plus-square mr-2"></i>
                    Přidat projekt
                </div>
                <div class="col-md-12 mb-3" v-if="toggle">
                    <form @submit.prevent="saveProject" ref="pridani" class="bg-white p-3 border rounded" >
                        <div class="form-group">
                            <label for="name">
                                Název
                            </label>
                            <input required type="text" id="name" class="form-control" v-model="form.name"
                                   placeholder="Název projektu..."/>
                        </div>
                        <div class="form-group">
                            <label for="realization">
                                Realizace
                            </label>
                            <input required type="month" id="realization" class="form-control"
                                   v-model="form.realization" placeholder="Datum realizace"/>
                        </div>
                        <div class="form-group">
                            <label for="info">
                                Popis
                            </label>
                            <vue-editor id="info" v-model="form.info" required :editorToolbar="customToolbar"></vue-editor>
                        </div>
                        <div class="form-group form-check">

                            <input id="show" type="checkbox" v-model="form.show" class="form-check-input"/>
                            <label for="show" class="form-check-label">
                                Ukázat na hlavní stránce
                            </label>
                        </div>
                        <div class="form-group">
                            <label for="main_photo">
                                Hlavní fotografie
                            </label>
                            <input required type="file" accept="image/*" id="main_photo" class="form-control-file"
                                   @change="setMainPhoto($event.target.files[0])"/>
                            <img :src="form.main_photo" class="img-fluid rounded mt-2" v-if="form.main_photo"/>
                        </div>
                        <div class="form-group">
                            <label for="house_plan">
                                Plán domu
                            </label>
                            <input required type="file" accept="image/*" id="house_plan" class="form-control-file"
                                   @change="setHousePlan($event.target.files[0])"/>
                            <img :src="form.house_plan" class="img-fluid rounded mt-2" v-if="form.house_plan"/>
                        </div>
                        <div class="row m-0">
                            <input class="btn btn-primary" type="submit" value="Uložit"/>
                            <div v-if="saved === true" class="ml-3 mt-2" style="color: green;">Úspěšně uloženo!</div>
                        </div>
                    </form>
                </div>
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-header">
                            Projekty
                        </div>
                        <div class="card-body">
                            <div class="d-flex projekt" v-for="project in projects.list" v-if="projects.list.length > 0">
                                <div class="col-5 flex-row d-flex">
                                    <div class="row">
                                    <img :src="project.main_photo_small" class="photoAdmin">
                                    <div v-for="photo in project.photos">
                                        <img :src="photo.path" class="photoAdmin">
                                    </div>
                                        <img :src="project.house_plan" class="photoAdmin">
                                    </div>

<!--                                    <div class="row" >-->
<!--                                        <div v-for="(photo, i) in project.photos" v-if="i>=3">-->
<!--                                            <img :src="photo.path" class="photoAdmin">-->
<!--                                        </div>-->
<!--                                    </div>-->
                                </div>
                                <div class="btn-group-sm ml-auto col-7 text-right">
                                    <div class="text-left">{{ project.name }}</div>
                                    <a class="btn btn-dark btn-sm" :href="'/admin/projekty/'+project.id+'/edit'">Upravit</a>
                                    <button class="btn btn-danger btn-sm" @click="deleteProject(project)">Odstranit
                                    </button>
                                </div>
                            <br/>
                                <div class="dropdown-divider"></div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <notifications group="success"/>
        </template>
        <template v-else>
            <dot-loader :color="'green'" class="d-flex justify-content-center"></dot-loader>
        </template>

    </div>
</template>

<script>
import moment from 'moment';
import DotLoader from 'vue-spinner/src/DotLoader';
import {VueEditor} from "vue2-editor";
Vue.component('pagination', require('laravel-vue-pagination'));
export default {
    name: "ProjectsComponent",
    components: {
        DotLoader,
        VueEditor
    },
    data: () => {
        return {
            form: {
                name: null,
                realization: moment().format('YYYY-MM'),
                info: null,
                main_photo: null,
                house_plan: null,
                show: false
            },
            projects: {
                data: {},
                list: []
            },
            loading: true,
            toggle: false,
            saved: false,
            customToolbar: [[{header: [false, 1, 2, 3, 4, 5, 6]}], ["bold", "italic", "underline"], [{list: "ordered"}, {list: "bullet"}], [
                {align: ""},
                {align: "center"},
                {align: "right"},
                {align: "justify"}
            ], ["link", "code-block"]],

        }
    },
    mounted() {
        this.getProjects();
        window.addEventListener('DOMContentLoaded', () => {
            this.loading = false;
        })

    },
    methods: {

        deleteProject(project) { //Deletes project
            axios.delete(window.location.pathname + "?id=" + project.id).catch(err => {
                console.log(err)
            }).then(res => {
                console.log(res);
                //SHOOT SOME TOAST
            }).finally(() => {
                this.getProjects()
            })
        },
        setMainPhoto(img) { //Sets uploaded photo to object as base64_encoded image
            if (img) {
                let reader = new FileReader();
                console.log(img)
                reader.readAsDataURL(img);
                reader.onload = () => {
                    this.form.main_photo = reader.result;
                }
            }


        },

        setHousePlan(img) { //Sets uploaded photo to object as base64_encoded image
            if (img) {
                let reader = new FileReader();
                console.log(img)
                reader.readAsDataURL(img);
                reader.onload = () => {
                    this.form.house_plan = reader.result;
                }
            }


        },
        saveProject() { //Creates a new project
            axios.post(window.location.pathname, {
                name: this.form.name,
                main_photo: this.form.main_photo,
                house_plan: this.form.house_plan,
                info: this.form.info,
                realization: this.form.realization,
                show: this.form.show
            }).catch(error => {
                console.log(error)
            }).then(res => {
                if (res.data.success) {
                    //TODO: NOTIFY
                    this.saved = true;
                    this.$notify({
                        type: 'success',
                        group: 'success',
                        title: 'Úspěch',
                        position: 'bottom left',
                        text: 'Úspěšně uloženo'
                    });
                    for (let key in this.form) {
                        this.form[key] = null
                    }
                    this.form.show = false
                }
            }).finally(() => {

                this.form.realization = moment().format('YYYY-MM')
                this.getProjects()
            })
        },
        getProjects() { //Gets a list of projects
            axios.get(window.location.pathname + "/admin/list")
                .then(res => {
                    this.projects.data = res.data
                    this.projects.list = res.data.data
                }).finally(() => {

            })
        }
    }
}
</script>

<style scoped>

.projekt {
    margin-bottom: 10px;
    align-items: center;
}

.photoAdmin {
    height: 45px;
    width: 70px;
    object-fit: cover;
}

</style>
