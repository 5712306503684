<template>
    <nav class="navbar navbar-expand-md navbar-light bg-white shadow-sm sticky-top"
         :class="{bigNav : scrollPosition < 150, 'smallNav': scrollPosition >= 150}">
        <div class="container" style="max-width: 1500px">

            <a class="navbar-brand" href="/#welcome">
                <img src="/images/logo.svg" class="logo-nav" :class="{ '' : scrollPosition < 150, 'smallLogo': scrollPosition >= 150}">
            </a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
<!--                <span class="navbar-toggler-icon"></span>-->
                <div class="toggler d-flex"><i class="fas fa-bars togglerbars mr-2"></i><span class="my-auto">MENU</span></div>
            </button>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <!-- Left Side Of Navbar -->
                <ul class="navbar-nav mr-auto">

                </ul>

                <!-- Right Side Of Navbar -->
                <ul class="navbar-nav ml-auto">
                    <li class="nav-item">
                        <a class="nav-link" href="/#projekty">Projekty</a>
                        <div class="link-underline"></div>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/#sluzby">Služby</a>
                        <div class="link-underline"></div>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/#spoluprace">Spolupráce</a>
                        <div class="link-underline"></div>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" href="/#kontakt">Kontakt</a>
                        <div class="link-underline"></div>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link btn-schuzka" href="/#schuzka">Sjednat schůzku</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
export default {
    name: "navbar",
    data() {
      return {
          scrollPosition: null
      }
    },

    methods: {
        updateScroll() {
            this.scrollPosition = window.scrollY
        }
    },

    mounted() {
        window.addEventListener('scroll', this.updateScroll);
    }

}
</script>

<style scoped>

</style>
