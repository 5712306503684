<template>
    <div>
    <div id="welcome">
        <div class="mask d-flex">
                <div class="m-auto text-center container" style="max-width: 1600px">
                    <h1 class="title mb-5">Jsme NB projekce - mladý profesionální tým. <br> Navrhujeme, projektujeme a realizujeme.</h1>
                    <h3 class="under-title">
                        Jsme architektonicko-projekční kancelář, která našim klientům zajišťuje kompletní servis v oblasti projektování staveb.
                    </h3>
                </div>
        </div>
    </div>

        <div class="introbox">
            <div class="introcontainer">
            <div class="introtext container">
                Naše architektonicko-projekční kancelář Vám nabízí kompletní služby v pozemním stavitelství.
                Zabýváme se novostavbami i rekonstrukcemi rodinných případně bytových domů.
                Zajistíme kompletní dodávky staveb na klíč včetně vypracování projektové dokumentace a služeb stavebního dozoru.
            </div>
            </div>
        </div>


    </div>
</template>

<script>
    export default {
        mounted() {
            console.log('Component mounted.')
        }
    }
</script>

