<template>
<div id="kontakt">
    <div class="container py-5">
        <h1 class="pb-5 mb-3 pt-3">Kontaktujte nás</h1>
        <div class="row">
        <div class="col-md-4">
            <div class="pic">
            <img src="/images/DSC_0007cut.jpg" id="foto">
            </div>
            <h2 class="mb-0">Vojtěch Jirouš</h2>
            <div class="text-muted mb-3">Projektový manažer</div>
            <a href="tel:+420 212 249 941">+420 212 249 941</a>
            <a href="mailto:info@nizkonakladovydum.cz" target="_self">info@nbprojekce.cz</a>
        </div>

        <div class="col-md-4">
            <div class="pic">
            <i class="fas fa-home icon"></i>
        </div>
            <h2 class="mb-3">Naše kancelář</h2>
            <a href="tel:+420 212 248 192">+420 212 248 192</a>
            <div>Sokolovská 106/42</div>
            <div>186 00  Praha - Karlín</div>
        </div>
            <div class="col-md-4">
                <div class="pic">
                <i class="fas fa-map-marker-alt icon"></i>
                </div>
                <h2 class="mb-3">Naše Sídlo</h2>
                <div>Tomkova 49</div>
                <div>549 54 Police nad Metují</div>
            </div>

        </div>
    </div>
    <div style="background-color: #8B0000">
        <iframe width="100%" height="350" style="border:0" loading="lazy" allowfullscreen
                src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJGVatW7yUC0cRidaM0OuobtI&key=AIzaSyDChrcWQEa5ywBzCIcdeXQlGCSGkGuKEiI"></iframe>
    </div>
</div>
</template>

<script>
export default {
    name: "Contact"
}
</script>

<style scoped>
.col-md-4 {
    display: flex;
    text-align: center;
    flex-direction: column;
    font-size: 1.2rem;
}

.container {
    max-width: 1500px;
}

a {
    color: #8B0000;
}

@media (max-width: 768px) {
    .col-md-4 {
        margin-bottom: 50px;
    }
}

</style>
