<template>
    <div class="d-flex">
        <div class="container row pt-5 mx-auto">
            <div class="col-md-12 ">
                <div class="card p-3">
                    <form @submit.prevent="saveEdit">
                        <div class="form-group">
                            <label for="nameEdit">Název</label>
                            <input id="nameEdit" type="text" class="form-control" v-model="project.name"/>
                        </div>
                        <div class="form-group">
                            <label for="dateEdit">Realizace</label>
                            <input id="dateEdit" type="month" class="form-control" v-model="project.realization"/>
                        </div>
                        <div class="form-group">
                            <label for="infoEdit">Popis</label>
                            <vue-editor id="infoEdit" v-model="project.info" required :editorToolbar="customToolbar"></vue-editor>
                        </div>
                        <div class="form-group form-check">

                            <input id="show" type="checkbox" v-model="project.show" class="form-check-input"/>
                            <label for="show" class="form-check-label">
                                Ukázat na hlavní stránce
                            </label>
                        </div>
                        <div class="form-group">
                            <label for="photoEdit">Hlavní foto</label>
                            <input id="photoEdit" type="file" accept="image/*"
                                   class="form-control-file" @change="setMainPhoto($event.target.files[0])"/>
                            <img :src="project.main_photo" class="img-fluid rounded p-0 mt-2"
                                 v-if="project.main_photo"/>
                        </div>
                        <div class="form-group">
                            <label for="housePlanEdit">Plán Domu</label>
                            <input id="housePlanEdit" type="file" accept="image/*"
                                   class="form-control-file" @change="setHousePlan($event.target.files[0])"/>
                            <img :src="project.house_plan" class="img-fluid rounded p-0 mt-2"
                                 v-if="project.house_plan"/>
                        </div>
                        <div class="row m-0">
                        <input type="submit" class="btn btn-primary" value="Uložit" @click="saved = true"/>
                        <div v-if="saved === true" class="ml-3 mt-2" style="color: green;">Úspěšně uloženo!</div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-md-12">
                <div class="card p-4">
                    <h4>Fotografie</h4>
                    <div class="form-group">
                        <label for="photos">Vyberte fotografie</label>
                        <input type="file" id="photos" accept="image/*" class="form-control-file"
                               @change="addPhoto($event.target.files)" multiple/>
                    </div>
                    <div class="jumbotron row p-3">
                        <div class="col-md" v-for="(photo, index) in project.photos" v-if="project.photos.length > 0">
                            <img :src="photo.path" class="img-fluid" v-if="photo.path"/>
                            <div v-if="movable">
                                <button class="btn btn-secondary" v-if="index !== 0" @click="move(index, -1)">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                         height="16" fill="currentColor" class="bi bi-arrow-left-circle-fill mr-0"
                                         viewBox="0 0 16 16">
                                        <path
                                            d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"/>
                                    </svg>
                                </button>

                                <button v-if="index !== project.photos.length - 1" class="btn btn-secondary"
                                        @click="move(index, 1)">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                         class="ml-0 bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                                        <path
                                            d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
                                    </svg>
                                </button>
                                <button class="btn btn-danger" @click="deletePhoto(photo, index)">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                         class="bi bi-trash-fill" viewBox="0 0 16 16">
                                        <path
                                            d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 .5-.5zM8 5a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-1 0v-7A.5.5 0 0 1 8 5zm3 .5v7a.5.5 0 0 1-1 0v-7a.5.5 0 0 1 1 0z"/>
                                    </svg>
                                </button>
                            </div>
                            <div v-else>
                                <button class="btn btn-secondary" disabled v-if="index !== 0">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                         height="16" fill="currentColor" class="bi bi-arrow-left-circle-fill mr-0"
                                         viewBox="0 0 16 16">
                                        <path
                                            d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z"/>
                                    </svg>
                                </button>

                                <button v-if="index !== project.photos.length - 1" disabled class="btn btn-secondary"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                         class="ml-0 bi bi-arrow-right-circle-fill" viewBox="0 0 16 16">
                                        <path
                                            d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z"/>
                                    </svg>
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <notifications group="success"/>
    </div>
</template>

<script>
import moment from "moment";

export default {
    name: "ProjectEditComponent",
    props: ["_project"],
    data: () => {
        return {
            project: {},
            movable: true,
            saved:false,
            customToolbar: [[{header: [false, 1, 2, 3, 4, 5, 6]}], ["bold", "italic", "underline"], [{list: "ordered"}, {list: "bullet"}], [
                {align: ""},
                {align: "center"},
                {align: "right"},
                {align: "justify"}
            ], ["link", "code-block"]],
        }
    },
    created() {
        this.project = this._project
        this.project.realization = moment(this.project.realization).format('YYYY-MM')
    },
    methods: {
        deletePhoto(photo) {
            console.log(photo)
            axios.delete('/admin/projekty/photo?id=' + photo.id).catch(err => {
                console.log(err);
            }).then(res => {
                console.log(res);
            }).finally(() => {
                axios.get('/admin/projekty/photos?id=' + photo.project_id).catch(err => {
                    console.log(err)
                }).then(res => {
                    this.project.photos = res.data;
                })
            })
        },
        async move(index, dir) {
            if (this.movable) {
                this.movable = false
                if (dir == -1) {
                    this.project.photos[index - 1].position += 1;
                    this.project.photos[index].position -= 1;
                } else {
                    this.project.photos[index].position += 1;
                    this.project.photos[index + 1].position -= 1;
                }
                await this.project.photos.sort((a, b) => {
                    return a.position - b.position;
                })
                this.movable = true
                console.log(this.project.photos)
            }
        },
        async addPhoto(images) {
            if (images.length > 0) {
                for (let i = 0; i < images.length; i++) {
                    let img = images.item(i);
                    if (!this.exists(img)) {
                        break;
                    }
                    let position = 1;
                    if (this.project.photos.length > 0) {
                        position = this.project.photos[this.project.photos.length - 1].position + 1
                    } else {
                        position = i + 1;
                    }
                    let reader = new FileReader();
                    reader.readAsDataURL(img);
                    reader.onload = async () => {
                        await this.project.photos.push({
                            name: img.name,
                            path: reader.result,
                            project_id: this.project.id,
                            position: position
                        });

                    }
                    await this.project.photos.sort((a, b) => {
                        return a.position - b.position;
                    })
                }

            }
        },
        exists(img) {
            let exists = true
            this.project.photos.forEach(photo => {
                if (photo.name === img.name) {
                    console.log("Already exists.")
                    exists = false
                }
            })
            return exists
        },
        saveEdit() {
            axios.put('/admin/projekty', this.project)
                .catch(error => {
                    console.log(error)
                }).then(res => {
                console.log(res);
                if (res.data.success == true) {
                    this.$notify({
                        type: 'success',
                        group: 'success',
                        title: 'Úspěch',
                        position: 'bottom left',
                        text: 'Úspěšně uloženo'
                    })
                }
            }).finally(() => {

            })
        },
        setMainPhoto(img) { //Sets uploaded photo to object as base64_encoded image
            if (img) {
                let reader = new FileReader();
                reader.readAsDataURL(img);
                reader.onload = () => {
                    this.project.main_photo = reader.result;
                }
            }
        },
        setHousePlan(img) { //Sets uploaded photo to object as base64_encoded image
            if (img) {
                let reader = new FileReader();
                reader.readAsDataURL(img);
                reader.onload = () => {
                    this.project.house_plan = reader.result;
                }
            }
        },
    }
}
</script>

<style scoped>

</style>
