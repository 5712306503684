<template>
    <div id="form" class="py-5" v-if="sent === false">
        <h1 class="pb-3">Domluvte si nezávaznou schůzku</h1>
        <h3 class="pb-4 text-center">Zavolejte nám na <a href="tel:+420 212 248 192">+420 212 248 192</a>, nebo nám zanechte zprávu.<br> Ozveme se Vám do 2 pracovních dní.</h3>
        <div class="container form">
            <form @submit.prevent="sendMail" class="col align-self-center d-flex flex-column">
                <div class="form-group">
                    <label for="name">
                        Jméno: *
                    </label>
                    <input type="text" id="name" v-model="form.name" class="form-control" required/>
                </div>
                <div class="form-group">
                    <label for="email">
                        Email: *
                    </label>
                    <input type="email" id="email" v-model="form.email" class="form-control" required/>
                </div>
                <div class="form-group">
                    <label for="mobile">
                        Telefon:
                    </label>
                    <input type="text" id="mobile" v-model="form.mobile" class="form-control"/>
                </div>
                <div class="form-group">
                    <label for="location">
                        Lokalita:
                    </label>
                    <input type="text" id="location" v-model="form.location" class="form-control"/>
                </div>
                <div class="form-group">
                    <label for="message">
                        Zpráva: *
                    </label>
                    <textarea id="message" class="" v-model="form.message" class="form-control" required/>
                </div>
                <div class="form-group">
                    <label for="protection">
                        Ochrana proti robotům, jaký je rok? *
                    </label>
                    <input type="text" id="protection" v-model="form.robot_protection" class="form-control" required/>
                </div>
                <input type="submit" class="btn btn-success mx-auto mt-2" @submit="sendMail; (sent = true)" href="#schuzka" value="Poslat zprávu"/>
            </form>
        </div>
    </div>
    <div style="padding: 6rem 0rem" v-else>
        <h1 class="pb-5">Vaše zpráva byla úspěšně odeslána</h1>
        <h3 class="pb-5 text-center">Ozveme se Vám do 2 pracovních dní.</h3>
    </div>
</template>

<script>
export default {
    name: "ContactForm",
    data: () => {
        return {
            form: {
                _token: document.getElementsByName('csrf-token')[0].content,
                name: null,
                email: null,
                mobile: null,
                location: null,
                message: null,
                robot_protection: null
            },

            sent: false
        }
    }, mounted() {
    },
    methods: {
        sendMail() {
            if(this.form.robot_protection == new Date().getFullYear()){
                axios.post('/mail', this.form).catch(err => {
                    console.log(err)
                }).then((res) => {
                    //SHOOT TOAST
                    this.sent = true
                    if(res.data.success){

                    }

                })
            } else {
                //SHOOT THAT MF ROBOT OUT
            }

        }
    }
}
</script>

<style scoped>
a {
    color: #8B0000;
}

.btn-success {
    background-color: rgba(139, 0, 0, 0.9) !important;;
    border-color: rgba(139, 0, 0, 0.7) !important;
}

.btn-success:hover {
    background-color: #8B0000 !important;
    border-color: rgba(139, 0, 0, 0.8) !important;
    color: white !important;
}

</style>
